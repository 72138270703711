/* eslint-disable space-before-function-paren */
/* eslint-disable quotes */
/* eslint-disable semi */
import UserClient from "../clients/User.client";

/** mutation types */
export const set_users = "set_users";
export const set_user = "set_user";
export const set_current_user = "set_current_user";
export const set_current_user_config = "set_current_user_config";
export const set_current_user_referrals = "set_current_user_referrals";
export const set_current_user_wallet_config = "set_current_user_wallet_config";
export const set_current_user_logs = "set_current_user_logs";
export const set_current_user_wallet_txns = "set_current_user_wallet_txns";
export const set_current_user_wallet_txn = "set_current_user_wallet_txn";
export const set_current_user_error_logs = "set_current_user_error_logs";
export const set_current_user_websites = "set_current_user_websites";
export const delete_new_user_endpoint = "delete_new_user_endpoint";
export const delete_user = "delete_user";

/** state */
const getDefaultState = () => {
  return {
    users: null,
    currentUser: {},
    currentUserLogs: null,
    currentUserReferrals: null,
    currentUserWalletTxns: null,
    currentUserWalletTxn: null,
    currentUserErrorLogs: null,
    currentUserWebsites: null,
    currentUserConfig: {},
    currentUserWalletConfig: {},
  };
};
const state = getDefaultState();

/** getters */
const getters = {
  users: (state) => state.users,
  currentUser: (state) => state.currentUser,
  currentUserLogs: (state) => state.currentUserLogs,
  currentUserReferrals: (state) => state.currentUserReferrals,
  currentUserWalletTxns: (state) => state.currentUserWalletTxns,
  currentUserWalletTxn: (state) => state.currentUserWalletTxn,
  currentUserErrorLogs: (state) => state.currentUserErrorLogs,
  currentUserWebsites: (state) => state.currentUserWebsites,
  currentUserConfig: (state) => state.currentUserConfig,
  currentUserWalletConfig: (state) => state.currentUserWalletConfig,
};

/** mutations */
const mutations = {
  [set_users](state, data) {
    state.users = data;
  },
  [set_user](state, data) {
    state.users = [...data, ...state.users];
  },
  [set_current_user](state, data) {
    state.currentUser = data;
  },
  [set_current_user_logs](state, data) {
    state.currentUserLogs = data;
  },
  [set_current_user_referrals](state, data) {
    state.currentUserReferrals = data;
  },
  [set_current_user_wallet_txns](state, data) {
    state.currentUserWalletTxns = data;
  },
  [set_current_user_wallet_txn](state, data) {
    state.currentUserWalletTxn = data;
  },
  [set_current_user_error_logs](state, data) {
    state.currentUserErrorLogs = data;
  },
  [set_current_user_websites](state, data) {
    state.currentUserWebsites = data;
  },
  [set_current_user_config](state, data) {
    state.currentUserConfig = data;
  },
  [set_current_user_wallet_config](state, data) {
    state.currentUserWalletConfig = data;
  },
  [delete_user](state, id) {
    const index = state.users.findIndex((user) => user.id === id);
    state.users.splice(index, 1);
  },
};

/** actions */
const actions = {
  clearCurrentUser: ({ commit }) => {
    commit(set_current_user, {});
    commit(set_current_user_logs, null);
    commit(set_current_user_wallet_txns, null);
    commit(set_current_user_wallet_txn, null);
    commit(set_current_user_error_logs, null);
    commit(set_current_user_config, {});
    commit(set_current_user_wallet_config, {});
    commit(set_current_user_referrals, null);
    commit(set_current_user_websites, null);
  },
  clearUserTxnDetails: ({ commit }) => {
    commit(set_current_user_wallet_txn, null);
  },
  getUsers: ({ commit }) => {
    return new UserClient().getUsers().then((response) => {
      commit(set_users, response.data);
      return Promise.resolve(response.data);
    });
  },
  createUser: ({ commit }, payload) => {
    return new UserClient().createUser(payload).then((response) => {
      commit(set_user, [response.data]);
      return Promise.resolve(response.data);
    });
  },
  getUserDetails: ({ commit }, id) => {
    return new UserClient().getUser(id).then((response) => {
      commit(set_current_user, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUser: ({ commit }, id) => {
    return new UserClient().getUser(id).then((response) => {
      return Promise.resolve(response.data);
    });
  },
  getUserLogs: ({ commit }, id) => {
    return new UserClient().getUserLogs(id).then((response) => {
      commit(set_current_user_logs, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUserReferrals: ({ commit }, id) => {
    return new UserClient().getUserReferrals(id).then((response) => {
      commit(set_current_user_referrals, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUserWalletTxns: ({ commit }, id) => {
    return new UserClient().getUserWalletTxns(id).then((response) => {
      commit(set_current_user_wallet_txns, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUserWalletTxn: ({ commit }, { id, txnId }) => {
    return new UserClient().getUserWalletTxn(id, txnId).then((response) => {
      commit(set_current_user_wallet_txn, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUserErrorLogs: ({ commit }, id) => {
    return new UserClient().getUserErrorLogs(id).then((response) => {
      commit(set_current_user_error_logs, response.data);
      return Promise.resolve(response.data);
    });
  },
  getUserWebsites: ({ commit }, id) => {
    return new UserClient().getUserWebsites(id).then((response) => {
      commit(set_current_user_websites, response.data);
      return Promise.resolve(response.data);
    });
  },
  editUser: ({ commit }, args) => {
    return new UserClient().editUser(args.payload, args.id);
  },
  deleteUserByPhoneNumber: (_, number) => {
    return new UserClient()
      .deleteUserByPhoneNumber(number)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  /** config */
  getUserConfig: ({ commit }, id) => {
    return new UserClient().getUserConfig(id).then((response) => {
      commit(set_current_user_config, response.data);
      return Promise.resolve(response.data);
    });
  },
  updateConfig: ({ commit }, { id, config }) => {
    return new UserClient()
      .updateUserConfig(id, config)
      .then((response) => {
        commit(set_current_user_config, config);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  /** wallet config */
  getUserWalletConfig: ({ commit }, id) => {
    return new UserClient().getUserWalletConfig(id).then((response) => {
      commit(set_current_user_wallet_config, response.data);
      return Promise.resolve(response.data);
    });
  },
  updateUserWalletConfig: ({ commit }, { id, config }) => {
    return new UserClient()
      .updateUserWalletConfig(id, config)
      .then((response) => {
        commit(set_current_user_wallet_config, config);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getUserOTPByPhoneNumber: ({ commit }, number) => {
    function showError() {
      commit(
        "ui/set_error",
        {
          title: "Failed to retrieve OTP",
          description: "User does not have an active OTP",
        },
        { root: true }
      );
    }
    return new UserClient()
      .getUserOTPByPhoneNumber(number)
      .then((response) => {
        if (!response.data) {
          showError();
        }
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        showError();
        return Promise.reject(error);
      });
  },
  sendPushNotification: ({ commit }, { id, params }) => {
    function showError(
      type = "set_error",
      message = "Unable to send push notifications"
    ) {
      commit(
        `ui/${type}`,
        {
          title: "Push Notifications",
          description: message,
        },
        { root: true }
      );
    }
    return new UserClient()
      .sendPushNotification(id, params)
      .then((response) => {
        showError("set_success", "Push notification sent successfully");
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        showError();
        return Promise.reject(error);
      });
  },
  updateUserConnection: ({ commit }, { userId, params }) => {
    function showError(
      type = "set_error",
      message = "Failed to disconnect user. Try again!"
    ) {
      commit(
        `ui/${type}`,
        {
          title: "Disconnect User Connection",
          description: message,
        },
        { root: true }
      );
    }
    return new UserClient()
      .updateUserConnection(userId, params)
      .then((response) => {
        showError("set_success", "User disconnected successfully");
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        showError();
        return Promise.reject(error);
      });
  },
  search: ({ commit }, payload) => {
    return new UserClient()
      .search(payload)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
