/* eslint-disable space-before-function-paren */
/* eslint-disable quotes */
/* eslint-disable semi */
// Import HTTP client
import AnalyticClient from "../clients/Analytic.client";
import UserClient from "../clients/User.client";

/** mutation types */
// eslint-disable-line
const set_user_count = "set_connections";
const set_user_connected_since = "set_user_connected_since";
const set_user_connected_online = "set_user_connected_online";
const set_user_logs = "set_user_logs";
const set_analytics = "set_analytics";
const clear = "clear";

/** state */
const getDefaultState = () => {
  return {
    userCount: 0,
    userConnectedSinceCount: 0,
    userConnectedOnline: 0,
    userConnectedSince: null,
    userLogs: null,
    analytics: {
      newuser: [],
      lastseen: [],
      telco: [],
      telco_usage: [],
      geocountry: [],
      geostate: [],
      messages: [],
      media: [],
      nacks: [],
      appversion: [],
      zerodatawebsites: [],
      zerodatausers: [],
    },
    timeSeriesMetricsByLastSeen: [],
  };
};
const state = getDefaultState();

/** getters */
const getters = {
  userCount: (state) => state.userCount,
  userConnectedSinceCount: (state) =>
    !state.userConnectedSince ? 0 : state.userConnectedSince.length,
  userConnectedOnlineCount: (state) =>
    !state.userConnectedOnline ? 0 : state.userConnectedOnline.length,
  userConnectedOnline: (state) => state.userConnectedOnline,
  userConnectedSince: (state) => state.userConnectedSince,
  totalConnections: (state) => state.connections,
  userLogs: (state) => state.userLogs,
  timeSeriesMetricsByNewUser: (state) => state.analytics.newuser,
  timeSeriesMetricsByLastseen: (state) => state.analytics.lastseen,
  timeSeriesMetricsByMessages: (state) => state.analytics.messages,
  timeSeriesMetricsByMedia: (state) => state.analytics.media,
  timeSeriesMetricsByNacks: (state) => state.analytics.nacks,
  timeSeriesMetricsByTelcoUsage: (state) => state.analytics.telco_usage,
  distributionMetricsByTelco: (state) => state.analytics.telco,
  distributionMetricsByGeoCountry: (state) => state.analytics.geocountry,
  distributionMetricsByGeoState: (state) => state.analytics.geostate,
  distributionMetricsByAppVersion: (state) => state.analytics.appversion,
  distributionMetricsByZerodataWebsites: (state) =>
    state.analytics.zerodatawebsites,
  distributionMetricsByZerodataUsers: (state) => state.analytics.zerodatausers,
};

/** mutations */
const mutations = {
  [set_user_count](state, data) {
    state.userCount = data;
  },
  [set_user_connected_since](state, data) {
    state.userConnectedSince = data;
  },
  [set_user_connected_online](state, data) {
    state.userConnectedOnline = data;
  },
  [set_user_logs](state, data) {
    state.userLogs = data;
  },
  [set_analytics](state, data) {
    state.analytics[data.key] = data.content;
  },
  [clear](state) {
    Object.assign(state, getDefaultState());
  },
};

/** actions */
const actions = {
  getActiveConnections: ({ commit }) => {
    return new AnalyticClient().getConnections().then((response) => {
      commit(set_user_count, response.data.count);
      Promise.resolve(response.data);
    });
  },
  getUsersByFilter: ({ commit }, { key, params }) => {
    return new AnalyticClient().getUsersByFilter(params).then((response) => {
      commit(key, response.data);
      Promise.resolve(response.data);
    });
  },
  getTimeSeriesMetrics: ({ commit }, { key, params }) => {
    return new AnalyticClient().getTimeSeriesFor(params).then((response) => {
      commit(set_analytics, { key, content: response.data });
      Promise.resolve(response.data);
    });
  },
  getDistributionMetrics: ({ commit }, { key, params }) => {
    return new AnalyticClient().getDistributionFor(params).then((response) => {
      commit(set_analytics, { key, content: response.data });
      Promise.resolve(response.data);
    });
  },
  getZerodataDistributionMetrics: ({ commit }, { key, params }) => {
    return new AnalyticClient()
      .getZerodataDistributionFor(params.path)
      .then((response) => {
        // transform data
        const transformedData = response.data.map((data) => ({
          label: data[params.label_key],
          count: data.usage,
        }));

        commit(set_analytics, { key, content: transformedData });
        Promise.resolve(response.data);
      });
  },
  getUserLogs: ({ commit }, id) => {
    return new UserClient().getUserLogs(id).then((response) => {
      commit(set_user_logs, response.data);
      Promise.resolve(response.data);
    });
  },
  clear: ({ commit }) => {
    commit(clear);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
