import BaseAuthClientWithApi from './BaseAuth.client'

export default class AnalyticClient extends BaseAuthClientWithApi {
  getConnections (id) {
    return this
      .client
      .get('/v1/users/count')
  }

  getUsersByFilter (params) {
    return this
      .client
      .get('/v1/users', { params })
  }

  getTimeSeriesFor (params) {
    return this
      .client
      .get('/v1/analytics/timeseries', { params })
  }

  getDistributionFor (params) {
    return this
      .client
      .get('/v1/analytics/distribution/users', { params })
  }

  getZerodataDistributionFor (path) {
    return this
      .client
      .get(`/v1/analytics/zerodata/${path}`)
  }
}
